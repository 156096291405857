var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"new-row flex flex-column justify-center bg-no-repeat bg-center bg-size-100",on:{"click":_vm.gotoVirtualLive}},[_c('div',{staticClass:"league-info font-12 flex align-center justify-center font-medium font-500"},[_c('span',{staticClass:"date"},[_vm._v(_vm._s(_vm._f("filterDate")(_vm.competition.matchTime)))]),_c('span',{staticClass:"m-l-5"},[_vm._v(_vm._s(_vm.startTime))]),_c('span',{staticClass:"m-l-5"},[_vm._v(_vm._s(_vm.competition.leagueChsShort)+" "+_vm._s(_vm.roundInfo))])]),_c('div',{staticClass:"team-info"},[_c('div',{staticClass:"flex m-t-5 align-center"},[_c('div',{staticClass:"team flex-1 flex flex-end font-12 font-medium font-500 text-black-3 align-center"},[_c('span',{staticClass:"name text-white text-right transform-center van-overflow-hidden text-clip"},[_vm._v(_vm._s(_vm.competition.homeChs)+_vm._s(_vm.ranking ? `[${_vm.competition.home_rank}]` : ''))]),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.homeIcon),expression:"homeIcon"}],staticClass:"team-icon m-l-5 border-50 d-inline-block overflow-hidden",attrs:{"alt":""}}),_c('span',{staticClass:"score text-white font-medium text-center d-inline-block font-16"},[_vm._v(_vm._s(_vm.competition.homeScore))])]),_c('span',{staticClass:"status d-inline-block text-center",class:{
                            'is-going': _vm.isGoing,
                        }},[_c('span',{staticClass:"delay-time font-400 font-12 d-inline-block transform-center"},[_vm._v(_vm._s(_vm.middleStatus))])]),_c('div',{staticClass:"team flex-1 flex font-12 font-medium font-500 text-black-3 align-center"},[_c('span',{staticClass:"score text-center d-inline-block text-white font-16"},[_vm._v(_vm._s(_vm.competition.awayScore))]),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.awayIcon),expression:"awayIcon"}],staticClass:"team-icon m-l-5 border-50 d-inline-block overflow-hidden",attrs:{"alt":""}}),_c('span',{staticClass:"name scale-9 text-white transform-center text-left van-overflow-hidden text-clip"},[_vm._v(_vm._s(_vm.ranking ? `[${_vm.competition.away_rank}]` : '')+_vm._s(_vm.competition.awayChs))])])]),_c('div',{staticClass:"upper-info m-t-5 flex align-center font-12 font-regular font-500 w-100 text-center"},[_c('div',{staticClass:"left flex align-center flex-end flex-1"},[_c('div',{staticClass:"penalty",class:{
                            'visibility-hidden': !_vm.cardTip
                        }},[_c('span',{staticClass:"red card d-inline-block"},[_c('span',{staticClass:"d-inline-block scale-7 transform-center"},[_vm._v(_vm._s(_vm.competition.homeRed))])]),_c('span',{staticClass:"yellow m-l-5 m-r-5 card d-inline-block"},[_c('span',{staticClass:"d-inline-block scale-7 transform-center"},[_vm._v(_vm._s(_vm.competition.homeYellow))])])])]),_c('div',{staticClass:"half transform-center"},[_c('span',[_vm._v("半:")]),_c('span',{staticClass:"number"},[_vm._v(_vm._s(_vm.competition.homeHalfScore))]),_c('span',{staticClass:"vertical-top"},[_vm._v("-")]),_c('span',{staticClass:"number"},[_vm._v(_vm._s(_vm.competition.awayHalfScore))])]),_c('div',{staticClass:"right flex-1 m-l-5 flex align-center"},[_c('div',{staticClass:"half transform-center"}),_c('div',{staticClass:"penalty",class:{
                            'visibility-hidden': !_vm.cardTip
                        }},[_c('span',{staticClass:"yellow m-l-5 card d-inline-block"},[_c('span',{staticClass:"d-inline-block scale-7 transform-center"},[_vm._v(_vm._s(_vm.competition.awayYellow))])]),_c('span',{staticClass:"red card m-l-5 d-inline-block"},[_c('span',{staticClass:"d-inline-block scale-7 transform-center"},[_vm._v(_vm._s(_vm.competition.awayRed))])])])])])]),_c('div',{staticClass:"asistant-info m-t-10 flex align-center justify-center"},[(_vm.isFuture && !_vm.hasLive)?_c('div',{staticClass:"subscribe-button text-center",class:{
                     'is-subscribed': _vm.isSubscribe
                 }},[_c('span',{staticClass:"font-regular",on:{"click":function($event){$event.stopPropagation();return _vm.subscribeHost.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.isSubscribe ? '已预约' : '预约直播'))])]):_vm._e(),(_vm.hasManyLive)?_c('span',{staticClass:"live-room m-l-10 m-r-10 d-inline-block bg-no-repeat bg-center bg-size-100",on:{"click":function($event){$event.stopPropagation();return _vm.openPopup.apply(null, arguments)}}}):_vm._e(),_c('span',{staticClass:"virtual-room d-inline-block bg-no-repeat bg-center bg-size-100",on:{"click":function($event){$event.stopPropagation();return _vm.gotoVirtualLive.apply(null, arguments)}}}),(_vm.showLives.length)?_c('div',{staticClass:"rooms p-l-10 p-r-10 overflow-x-auto"},[_c('div',{staticClass:"flex align-center box",style:({
                    width: `${_vm.roomWidth}px`
                })},_vm._l((_vm.showLives),function(anchor){return _c('span',{key:anchor.room_id,staticClass:"d-inline-block m-r-5 room-icon bg-center bg-no-repeat bg-size-100",class:{isLive: anchor.live_status === 2},style:({
                         backgroundImage: `url(${anchor.img ? anchor.img : _vm.userEmptyImg})`
                        }),on:{"click":function($event){$event.stopPropagation();return _vm.goToLiveRoom(anchor)}}})}),0)]):_vm._e(),(_vm.showScheduled && _vm.showScheduled.length)?_c('div',{staticClass:"rooms p-l-10 p-r-10 overflow-x-auto"},[_c('div',{staticClass:"flex align-center box",style:({
                    width: `${_vm.roomWidth}px`
                })},_vm._l((_vm.showScheduled),function(anchor){return _c('span',{key:anchor.room_id,staticClass:"d-inline-block m-r-5 room-icon bg-center bg-no-repeat bg-size-100",style:({
                         backgroundImage: `url(${anchor.img ? anchor.img : _vm.userEmptyImg})`
                        }),on:{"click":function($event){$event.stopPropagation();return _vm.goToLiveRoom(anchor)}}})}),0)]):_vm._e()]),_c('van-popup',{style:({ height: '40%' }),attrs:{"closeable":"","close-icon":"close","position":"bottom"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('div',{staticClass:"popup-box"},[_c('p',{staticClass:"font-16 m-t-15 text-center font-500 text-black-3 font-medium"},[_vm._v("选择直播间")]),_c('div',{staticClass:"anchors"},[_vm._l((_vm.competition.anchor_list),function(anchor){return [_c('LiveHostCard',{key:anchor.room_id,attrs:{"anchor":anchor,"match-info":_vm.competition}})]})],2)])]),(!!_vm.competition.material_num)?_c('i',{staticClass:"haveTag"}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }