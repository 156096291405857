var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row flex justify-between align-center bg-no-repeat bg-center bg-size-100"},[_c('div',{staticClass:"left-section p-relative",class:{w30: _vm.isScheduled},on:{"click":_vm.gotoVirtualLive}},[_c('div',{staticClass:"battle p-r-10"},[_c('div',{staticClass:"battle-name font-12 flex align-center justify-between"},[_c('div',{staticClass:"battle-time"},[_c('span',{staticClass:"font-12 m-r-10 font-regular d-inline-block text-primary scale-83"},[_vm._v(_vm._s(_vm._f("filterYear")(_vm.competition.matchTime))+_vm._s(_vm._f("filterTime")(_vm.competition.matchTime)))])]),_c('div',{staticClass:"status font-12 text-left",class:{'m-l-15': _vm.isScheduled}},[(_vm.isGoing)?_c('span',{staticClass:"scale-7 d-inline-block"},[_vm._v(_vm._s(_vm.shortTime)+" "+_vm._s(_vm.competition.remainTime))]):_c('span',{staticClass:"other-status scale-7 d-inline-block"},[_vm._v(_vm._s(_vm.middleStatus.charAt(0)))])]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isScheduled),expression:"!isScheduled"}],staticClass:"placeholder text-primary scale-9 font-12 font-medium font-500"},[_vm._v(" 分差 "+_vm._s(_vm.scoreMinus)+" ")])]),_c('div',{staticClass:"new-line"},[_c('span',{staticClass:"font-12 m-r-10 font-regular text-primary d-inline-block scale-83"},[_vm._v(_vm._s(_vm.competition.leagueChsShort))])]),_c('div',{staticClass:"team font-12 m-t-5 text-white font-medium flex align-center justify-between"},[_c('span',{staticClass:"team-name text-overflow-hidden"},[_vm._v(_vm._s(_vm.ranking ? `[${_vm.competition.home_rank}]` : '')+_vm._s(_vm.competition.homeChs))]),_c('div',{staticClass:"scores flex align-center text-center",class:{
                        'visibility-hidden': _vm.isFuture
                    }},[_c('span',[_vm._v(_vm._s(_vm.competition.home1))]),_c('span',[_vm._v(_vm._s(_vm.competition.home2))]),_c('span',[_vm._v(_vm._s(_vm.competition.home3))]),_c('span',[_vm._v(_vm._s(_vm.competition.home4))]),_c('span',{class:{
                        'visibility-hidden': !_vm.competition.homeOT1
                    }},[_vm._v(_vm._s(_vm.competition.homeOT1))]),_c('span',{class:{
                        'visibility-hidden': !_vm.competition.homeOT2
                    }},[_vm._v(_vm._s(_vm.competition.homeOT2))]),_c('span',{class:{
                        'visibility-hidden': !_vm.competition.homeOT3
                    }},[_vm._v(_vm._s(_vm.competition.homeOT3))])]),_c('span',{staticClass:"total-score font-12 text-white text-center font-medium font-500",class:{
                        'visibility-hidden': _vm.isFuture
                    }},[_vm._v(_vm._s(_vm.competition.homeScore))])]),_c('div',{staticClass:"team font-12 m-t-5 text-white font-medium flex align-center justify-between"},[_c('span',{staticClass:"team-name text-overflow-hidden"},[_vm._v(_vm._s(_vm.ranking ? `[${_vm.competition.away_rank}]` : '')+_vm._s(_vm.competition.awayChs))]),_c('div',{staticClass:"scores flex align-center text-center",class:{
                        'visibility-hidden': _vm.isFuture
                    }},[_c('span',[_vm._v(_vm._s(_vm.competition.away1))]),_c('span',[_vm._v(_vm._s(_vm.competition.away2))]),_c('span',[_vm._v(_vm._s(_vm.competition.away3))]),_c('span',[_vm._v(_vm._s(_vm.competition.away4))]),_c('span',{class:{
                        'visibility-hidden': !_vm.competition.awayOT1
                    }},[_vm._v(_vm._s(_vm.competition.awayOT1))]),_c('span',{class:{
                        'visibility-hidden': !_vm.competition.awayOT2
                    }},[_vm._v(_vm._s(_vm.competition.awayOT2))]),_c('span',{class:{
                        'visibility-hidden': !_vm.competition.awayOT3
                    }},[_vm._v(_vm._s(_vm.competition.awayOT3))])]),_c('span',{staticClass:"total-score font-12 text-white text-center font-medium font-500",class:{
                        'visibility-hidden': _vm.isFuture
                    }},[_vm._v(_vm._s(_vm.competition.awayScore))])])])]),(_vm.isScheduled)?_c('div',{staticClass:"scheduled_anchor"},_vm._l((_vm.competition.scheduled_anchor),function(item){return _c('img',{key:item.anchor_id,attrs:{"src":item.img?item.img: require('@/assets/logo.png'),"alt":""}})}),0):_vm._e(),_c('div',{staticClass:"right-buttons flex align-center"},[_c('div',{staticClass:"border"}),_c('div',{staticClass:"flex flex-column flex-1 align-center justify-center p-t-15"},[_c('span',{staticClass:"font-12 scale-9 text-primary font-medium font-500 p-t-3 score"},[_vm._v("总分 "+_vm._s(_vm.totalScore))]),(_vm.hasLive)?_c('div',{staticClass:"m-l-10 m-r-10"},[_c('div',{staticClass:"icon-box"},[_c('span',{staticClass:"live-room d-inline-block bg-no-repeat bg-center bg-size-100",on:{"click":function($event){$event.stopPropagation();return _vm.openPopup.apply(null, arguments)}}})])]):_vm._e(),_c('div',{staticClass:"icon-box"},[_c('span',{staticClass:"virtual-room d-inline-block bg-no-repeat bg-center bg-size-100",on:{"click":function($event){$event.stopPropagation();return _vm.gotoVirtualLive.apply(null, arguments)}}})]),(!_vm.hasLive && _vm.isFuture)?_c('div',{staticClass:"subscribe-button m-t-5 icon-box text-center m-l-10 m-r-10",class:{
                   'is-subscribed': _vm.isSubscribe
               }},[_c('span',{staticClass:"font-regular",on:{"click":function($event){$event.stopPropagation();return _vm.subscribeHost.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.isSubscribe ? '已预约' : '预约'))])]):_vm._e()])]),_c('van-popup',{style:({ height: '40%' }),attrs:{"closeable":"","close-icon":"close","position":"bottom"},on:{"click-close-icon":function($event){$event.stopPropagation();_vm.show = false}},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('p',{staticClass:"font-16 m-t-15 p-b-10 text-center font-500 text-white font-medium"},[_vm._v("选择直播间")]),_c('div',{staticClass:"anchors"},[_c('div',{staticClass:"border"}),_vm._l((_vm.competition.anchor_list),function(anchor){return [_c('LiveHostCard',{key:anchor.room_id,attrs:{"anchor":anchor,"match-info":_vm.competition}})]})],2)]),(!!_vm.competition.material_num)?_c('i',{staticClass:"haveTag"}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }