var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card flex align-center bg-white"},[_c('div',{staticClass:"left h-100 p-r-5 w-50 flex flex-column align-center"},[_c('div',{staticClass:"league w-100 flex align-center"},[_c('span',{staticClass:"league-title font-12 font-medium text-center font-500",style:({
                    color: '#2B8ED4',
                    border: `1px solid #2B8ED4`
                })},[_vm._v(_vm._s(_vm.competition.leagueChsShort))]),_c('div',{staticClass:"font-12 m-l-10 font-medium font-500 text-black-3 scale-9 transform-center"},[_c('span',[_vm._v(_vm._s(_vm.matchTime))])])]),_c('div',{staticClass:"team-info m-t-10 w-100 font-500 font-12 font-medium text-black-3 flex align-center justify-center"},[_c('div',{staticClass:"team flex align-center"},[_c('span',{staticClass:"team-name p-r-5 text-clip text-right"},[_vm._v(_vm._s(_vm.competition.homeChs))]),_c('span',{staticClass:"icon d-inline-block border-50 d-inline-block bg-no-repeat bg-center bg-size-cover",style:({
                        backgroundImage: `url(${_vm.homeIcon})`
                    })})]),_c('span',{staticClass:"font-13 text-center divider"},[_vm._v("-")]),_c('div',{staticClass:"team flex align-center"},[_c('span',{staticClass:"icon d-inline-block border-50 d-inline-block bg-no-repeat bg-center bg-size-cover",style:({
                        backgroundImage: `url(${_vm.awayIcon})`
                    })}),_c('span',{staticClass:"team-name p-l-5 text-clip text-left"},[_vm._v(_vm._s(_vm.competition.awayChs))])])])]),_c('div',{staticClass:"right w-50 h-100 flex align-center"},[_c('div',{staticClass:"left-border"}),_c('div',{staticClass:"hosts p-l-5 p-r-5 flex align-center flex-no-wrap overflow-x-auto"},[_vm._l((_vm.anchors),function(anchor){return [_c('span',{key:anchor.id,staticClass:"host m-l-5 border-50 shrink-0 bg-no-repeat bg-center bg-size-cover",style:({
                        backgroundImage: anchor.img ? `url(${anchor.img})` : `url(${_vm.userEmptyImg})`
                    }),on:{"click":function($event){return _vm.viewAnchor(anchor)}}})]})],2)]),_c('van-dialog',{attrs:{"close-on-click-overlay":"","lock-scroll":"","show-confirm-button":false},model:{value:(_vm.showAnchor),callback:function ($$v) {_vm.showAnchor=$$v},expression:"showAnchor"}},[_c('div',{staticClass:"host-box bg-center bg-size-100 p-relative bg-no-repeat"},[_c('van-icon',{staticClass:"close p-absolute",attrs:{"size":"23","color":"#000","name":"close"},on:{"click":_vm.closeDialog}}),_c('div',{staticClass:"host-img p-absolute flex"},[_c('div',{staticClass:"img"},[_c('img',{staticClass:"border-50",attrs:{"src":_vm.viewAnchorImg,"alt":""}}),(_vm.currentAnchor.live_status * 1 === 2)?_c('span',{staticClass:"status d-inline-block p-absolute text-center font-12 font-regular font-400 text-color"},[_c('span',{staticClass:"d-inline-block transform-center scale-9"},[_vm._v("直播中")])]):_vm._e()]),_c('div',{staticClass:"fans font-12 m-l-5 font-regular font-400"},[_vm._v("粉丝："+_vm._s(_vm.currentAnchor.follow || 0))])]),_c('div',{staticClass:"info p-l-25"},[_c('div',{staticClass:"font-14 font-medium text-black-3 font-500"},[_c('span',[_vm._v("主播：")]),_c('span',[_vm._v(_vm._s(_vm.currentAnchor.anchor_name))])])]),_c('span',{staticClass:"follow-button m-t-25 d-inline-block text-center text-color font-16 font-medium font-500",on:{"click":_vm.follow}},[_vm._v(_vm._s(_vm.followAnchor))])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }