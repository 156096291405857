var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-100 h-100 competition-container",class:{
         'is-expand': _vm.showTimeLine,
         'is-small': _vm.isMyBooked
    }},[_c('div',{staticClass:"common-filter van-overflow-hidden"},[_c('div',{staticClass:"first-level-tab p-b-5 m-l-15 m-r-10 p-t-5 flex justify-between align-center"},[_c('ul',{staticClass:"flex align-center flex-1"},[_c('li',{staticClass:"sport-tab p-l-5 p-r-5 font-14 font-medium text-center",class:{'is-active': _vm.sportTabId === 0 },on:{"click":function($event){return _vm.changeSportTab()}}},[_vm._v(" 我的预约 ")]),_vm._l((_vm.sportTabs),function(tab){return _c('li',{key:tab.id,staticClass:"sport-tab m-l-5 p-l-5 p-r-5 font-14 font-599 font-medium text-center",class:{'is-active': _vm.sportTabId === tab.id },on:{"click":function($event){return _vm.changeSportTab(tab)}}},[_vm._v(" "+_vm._s(tab.name)+" ")])})],2)]),(_vm.isCompetitionList)?_c('div',{staticClass:"sub-class p-relative"},[_c('van-tabs',{attrs:{"title-active-color":"#FBDFC6","title-inactive-color":"#fff"},on:{"change":_vm.resetDate},model:{value:(_vm.subTabId),callback:function ($$v) {_vm.subTabId=$$v},expression:"subTabId"}},_vm._l((_vm.subTabs),function(subItem){return _c('van-tab',{key:subItem.id,attrs:{"name":subItem.id,"title":`${subItem.name}`}})}),1)],1):_vm._e(),(_vm.showTimeLine)?_c('TimeLine',{key:_vm.showPrev,attrs:{"time":_vm.date,"show-prev":_vm.showPrev},on:{"update:time":function($event){_vm.date=$event}}}):_vm._e()],1),_c('div',{key:_vm.refreshKey,staticClass:"content-wrapper",class:{
        'competition-section': !_vm.isMyBooked,
        'my-schedule': _vm.isMyBooked,
        'has-time-line': _vm.showTimeLine
    }},[(!_vm.isMyBooked)?[(!_vm.isHost)?_c('div',{staticClass:"h-100 overflow-hidden"},[_c('CompetitionList',{key:_vm.updateListKey,attrs:{"playing":_vm.subTabId,"league-ids":_vm.leagueIds,"league-type":_vm.sportTabId,"has-three-leagues":_vm.hasThreeLeagues,"day":_vm.date,"mode":_vm.mode,"totals":_vm.totals},on:{"totals":_vm.handlerTotals}})],1):[_c('HostCompetition',{attrs:{"day":_vm.date}})]]:_c('div',{staticClass:"h-100"},[_c('MySchedule',{attrs:{"mode":_vm.mode}})],1)],2),_c('van-action-sheet',{style:({ height: '40%' }),attrs:{"round":false,"title":"选择直播间"},model:{value:(_vm.chooseLiveDialog),callback:function ($$v) {_vm.chooseLiveDialog=$$v},expression:"chooseLiveDialog"}},[_c('div',{staticClass:"content"},[_c('van-list',{attrs:{"finished":_vm.finished},on:{"load":_vm.onLoad}},[_vm._l((_vm.liveData),function(live){return [_c('ChooseLive',{key:live.id,attrs:{"liveItem":live}})]})],2)],1)]),(_vm.showFilter)?_c('FilterLeagueTypes',{staticClass:"p-fixed filters",attrs:{"league-ids":_vm.leagueIds,"league-type":_vm.sportTabId,"has-three-leagues":_vm.hasThreeLeagues},on:{"update:leagueIds":function($event){_vm.leagueIds=$event},"update:league-ids":function($event){_vm.leagueIds=$event},"update:hasThreeLeagues":function($event){_vm.hasThreeLeagues=$event},"update:has-three-leagues":function($event){_vm.hasThreeLeagues=$event},"closeFilter":_vm.closeFilter}}):_vm._e(),(_vm.showSettings)?_c('Settings',{staticClass:"p-fixed w-100 h-100 settings",attrs:{"value":_vm.showSettings,"sportTabId":_vm.sportTabId},on:{"update:value":function($event){_vm.showSettings=$event}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }